import { SectionMenu } from 'components';

enum DateFilterOptionEnum {
	FORTHCOMING = 'FORTHCOMING',
	ONDEMAND = 'ONDEMAND'
}

const dateFilterMenuOptions: Array<SectionMenu<DateFilterOptionEnum>> = [
	{
		id: DateFilterOptionEnum.FORTHCOMING,
		localeId: 'catalog.home.forth-coming.menu'
	},
	{
		id: DateFilterOptionEnum.ONDEMAND,
		localeId: 'catalog.home.ondemand.menu'
	}
];

const dateFilterOptions = [DateFilterOptionEnum.FORTHCOMING, DateFilterOptionEnum.ONDEMAND];

export { dateFilterMenuOptions, dateFilterOptions, DateFilterOptionEnum };
